import React from 'react';

import Layout from '../components/Layout';

import { StaticQuery, graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Icon from '@bahaide/shared/components/Icon';

function BicentenaryHomepage() {
    const data = useStaticQuery(graphql`
        query {
        bannerImage: file(relativePath: {eq: "header-banner.jpg"}) {
            childImageSharp {
              fluid(maxHeight: 1600, maxWidth: 4000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          latestPost: allFile(filter: {sourceInstanceName: {eq: "posts-2019"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, limit: 1) {
            edges {
              node {
                childMarkdownRemark {
                  frontmatter {
                    city
                    title
                    date(formatString: "DD. MMMM YYYY", locale: "de")
                    feature_image {
                      childImageSharp {
                        fluid(maxWidth: 1024, quality: 80) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    gallery_images {
                        image: image_file {
                            childImageSharp {
                                fluid(maxWidth: 1024, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                  }
                  excerpt(pruneLength: 100, format: PLAIN)
                  fields {
                    slug
                  }
                }
              }
            }
          }
          secondRowPosts: allFile(filter: {sourceInstanceName: {eq: "posts-2019"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, limit: 3, skip: 1) {
            edges {
              node {
                childMarkdownRemark {
                  frontmatter {
                    city
                    title
                    date(formatString: "DD. MMMM YYYY", locale: "de")
                    feature_image {
                      childImageSharp {
                        fluid(maxWidth: 600, maxHeight: 400, quality: 80) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    gallery_images {
                        image: image_file {
                            childImageSharp {
                                fluid(maxWidth: 600, maxHeight: 400, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                  }
                  excerpt(pruneLength: 100, format: PLAIN)
                  fields {
                    slug
                  }
                }
              }
            }
          }
          thirdRowPosts: allFile(filter: {sourceInstanceName: {eq: "posts-2019"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, limit: 2, skip: 4) {
            edges {
              node {
                childMarkdownRemark {
                  frontmatter {
                    city
                    title
                    date(formatString: "DD. MMMM YYYY", locale: "de")
                    feature_image {
                      childImageSharp {
                        fluid(maxWidth: 600, maxHeight: 400, quality: 80) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    gallery_images {
                        image: image_file {
                            childImageSharp {
                                fluid(maxWidth: 600, maxHeight: 400, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                  }
                  excerpt(pruneLength: 100, format: PLAIN)
                  fields {
                    slug
                  }
                }
              }
            }
          }
        }
    `);

    return (
        <Layout
            site="bicentenary"
            title="200 Jahrfeier"
            description="200 Jahrfeier"
        >
            <main id="main" role="main">
                <div className="intro-block">
                    <Img
                        backgroundColor="#f8f6f4"
                        fluid={data.bannerImage.childImageSharp.fluid}
                    />
                    <div className="text-holder text-holder--center">
                        <blockquote>
                            <q>Der 200. Jahrestag der Geburt des Báb</q>
                        </blockquote>
                    </div>
                </div>
                <div className="container my-10">
                    <div className="flex flex-col md:flex-row my-6">
                        <div className="w-full md:w-2/3 md:mr-10">
                            <h2 className="md:text-4xl serif">Dawn of the Light / Aufgang des Lichts</h2>
                            <div className="video-wrapper my-4">
                                <iframe src="https://www.youtube.com/embed/rR7fXJznbOA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <p className="text-xs pl-4 w-5/6 md:w-3/4 leading-snug md:leading-normal">Der 48-minütige Film folgt der persönlichen Suche nach Wahrheit und Sinn, auf die sich acht Menschen aus verschiedenen Teilen der Welt begeben haben. Sie erzählen von ihrer Entdeckung, dass Gott zwei göttliche Boten – den Báb und Bahá’u’lláh – gesandt hat, deren Lehren das menschliche Denken und Verhalten revolutionieren. Die Geschichten der acht Freunde werden vor dem Hintergrund des bemerkenswerten Lebens des Báb, des Vorläufers Bahá’u’lláhs, dargestellt.</p>
                        </div>
                        <div className="w-full md:w-1/3 py-6 md:px-5 mt-6 md:mt-16">
                            <h2 className="serif text-2xl">Eine Botschaft vom Universalen Haus der Gerechtigkeit</h2>
                            <a className="cursor-pointer" href="/media/uploads/documents/das-universale-haus-der-gerechtigkeit-botschaft.pdf">Hier lesen</a>
                        </div>
                    </div>
                    <div className="md:flex md:flex-row md:justify-around">
                        <p className="mb-4 md:mb-0 md:mr-6">
                            Der Bahá’í-Glaube beruht auf zwei kurz
                            aufeinander folgenden{' '}
                            <OutboundLink href="https://www.bahai.de/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-offenbarung/">
                                Gottesoffenbarungen
                            </OutboundLink>
                            : die des{' '}
                            <OutboundLink href="https://www.bahai.de/woran-bahai-glauben/bahaullah-und-sein-anspruch/der-bab/">
                                Báb
                            </OutboundLink>{' '}
                            und die von{' '}
                            <OutboundLink href="https://www.bahai.de/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/">
                                Bahá’u’lláh
                            </OutboundLink>
                            . Die Bahá’í sehen die Größe des Báb (zu
                            dt.: „das Tor“, 1819-1850) nicht nur darin,
                            dass Er „der gottberufene Vorläufer“ der
                            Offenbarung Bahá’u’lláhs ist, sondern noch
                            bedeutender darin, dass Er – göttlich
                            bestimmt – „mit Kräften ausgerüstet war, wie
                            sie dem Begründer einer eigenen religiösen
                            Sendung innewohnen.“ Die Sendung des Báb
                            dauerte nur neun Jahre. Mit ihr wurde der
                            Blick auf die Offenbarung Bahá’u’lláhs
                            gelenkt.
                        </p>
                        <p>
                            Bahá’í-Gemeinden weltweit und in ganz
                            Deutschland bereiten sich auf das 200.
                            Jubiläum der Geburt des Báb am 29. Oktober
                            2019 vor, in dem sie Sein Leben und Wirken
                            in vielfältiger Weise durch Theater, Film,
                            Poesie, Musik, Malerei und im Rahmen ihrer
                            Gemeindeaktivitäten würdigen. Diese Werke
                            sollen zum Nachdenken anregen über den
                            Beginn des Bahá‘í-Glaubens, seine Lehren und
                            wie diese in die Gesellschaft damals wie
                            heute hineinwirken.
                        </p>
                    </div>
                </div>
                <div className="container mb-6">
                    {data.latestPost.edges.length ? (
                        <Link
                            className="flex flex-col my-6 bg-blue-800 md:flex-row hover:bg-blue-900 hover:shadow-lg"
                            to={`/post${data.latestPost.edges[0].node.childMarkdownRemark.fields.slug}`}
                        >
                            <div className="md:flex-shrink-0 w-full md:w-1/2">
                                <Img
                                    className="mx-auto"
                                    fluid={
                                        data.latestPost.edges[0].node
                                            .childMarkdownRemark.frontmatter.feature_image != null ? (
                                                data.latestPost.edges[0].node
                                                    .childMarkdownRemark.frontmatter.feature_image
                                                    .childImageSharp.fluid
                                            ) : (
                                                data.latestPost.edges[0].node
                                                    .childMarkdownRemark.frontmatter.gallery_images[0].image
                                                    .childImageSharp.fluid
                                            )
                                    }
                                />
                            </div>
                            <div className="flex-1 self-center text-left p-6">
                                <h2 className="text-3xl font-bold text-white serif">
                                    {
                                        data.latestPost.edges[0].node
                                            .childMarkdownRemark.frontmatter.title
                                    }
                                </h2>
                                <div className="text-base text-gray-500">
                                    {
                                        data.latestPost.edges[0].node
                                            .childMarkdownRemark.frontmatter.date
                                    }
                                    {` `}
                                    {
                                        data.latestPost.edges[0].node
                                            .childMarkdownRemark.frontmatter.city && 
                                            <span className="font-serif italic opacity-25">
                                            {
                                                data.latestPost.edges[0].node
                                                    .childMarkdownRemark.frontmatter.city
                                            }
                                            </span>
                                    }
                                </div>
                                <div className="text-base text-white">
                                    {
                                        data.latestPost.edges[0].node
                                            .childMarkdownRemark.excerpt
                                    }
                                </div>
                            </div>
                        </Link>
                    ) : null}
                </div>
                <div className="container my-6">
                    {data.secondRowPosts.edges.length ? (
                        <div className="flex flex-col md:flex-row my-6 justify-between">
                            {data.secondRowPosts.edges.map(
                                (post, i) => {
                                    return (
                                        <Link
                                            className="flex flex-col pr-15 hover:bg-gray-200 hover:shadow-lg overflow-hidden md:w-1/3 md:mr-6 md:last:mr-0 mb-6 md:mb-0"
                                            key={i}
                                            to={`/post${post.node.childMarkdownRemark.fields.slug}`}
                                        >
                                            <div className="md:flex-shrink-0">
                                                <Img
                                                    fluid={
                                                        post.node
                                                            .childMarkdownRemark.frontmatter.feature_image != null ? (
                                                                post.node
                                                                    .childMarkdownRemark.frontmatter.feature_image
                                                                    .childImageSharp.fluid
                                                            ) : (
                                                                post.node
                                                                    .childMarkdownRemark.frontmatter.gallery_images[0].image
                                                                    .childImageSharp.fluid
                                                            )
                                                    }
                                                />
                                            </div>
                                            <div className="flex-1 text-left py-4 px-3">
                                                <h2 className="text-xl font-bold text-blue-900 serif">
                                                    {
                                                        post.node
                                                            .childMarkdownRemark
                                                            .frontmatter
                                                            .title
                                                    }
                                                </h2>
                                                <div className="text-base text-gray-500">
                                                    {
                                                        post.node
                                                            .childMarkdownRemark
                                                            .frontmatter
                                                            .date
                                                    }
                                                    {` `}
                                                    <span className="font-serif italic opacity-50">
                                                    {
                                                        post.node.childMarkdownRemark.frontmatter.city
                                                    }
                                                    </span>
                                                </div>
                                                <div className="text-base" style={{
                                                    color: '#5c5956'
                                                }}>
                                                    {post.node.childMarkdownRemark.excerpt}
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                }
                            )}
                        </div>
                    ) : null}
                </div>
                <div className="container my-6">
                    {data.thirdRowPosts.edges.length ? (
                        <div className="flex flex-col md:flex-row my-6 justify-between">
                            {data.thirdRowPosts.edges.map(
                                (post, i) => {
                                    return (
                                        <Link
                                            className="flex flex-col pr-15 hover:bg-gray-200 hover:shadow-lg overflow-hidden md:w-1/2 md:mr-6 md:last:mr-0 mb-6 md:mb-0"
                                            key={i}
                                            to={`/post${post.node.childMarkdownRemark.fields.slug}`}
                                        >
                                            <div className="md:flex-shrink-0">
                                                <Img
                                                    fluid={
                                                        post.node
                                                            .childMarkdownRemark.frontmatter.feature_image != null ? (
                                                                post.node
                                                                    .childMarkdownRemark.frontmatter.feature_image
                                                                    .childImageSharp.fluid
                                                            ) : (
                                                                post.node
                                                                    .childMarkdownRemark.frontmatter.gallery_images[0].image
                                                                    .childImageSharp.fluid
                                                            )
                                                    }
                                                />
                                            </div>
                                            <div className="flex-1 text-left py-4 px-3">
                                                <h2 className="text-xl font-bold text-blue-900 serif">
                                                    {
                                                        post.node
                                                            .childMarkdownRemark
                                                            .frontmatter
                                                            .title
                                                    }
                                                </h2>
                                                <div className="text-base text-gray-500">
                                                    {
                                                        post.node
                                                            .childMarkdownRemark
                                                            .frontmatter
                                                            .date
                                                    }
                                                    {` `}
                                                    <span className="font-serif italic opacity-50">
                                                    {
                                                        post.node.childMarkdownRemark.frontmatter.city
                                                    }
                                                    </span>
                                                </div>
                                                <div className="text-base" style={{
                                                    color: '#5c5956'
                                                }}>
                                                    {post.node.childMarkdownRemark.excerpt}
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                }
                            )}
                        </div>
                    ) : null}
                    <Link
                        className="flex justify-end text-base"
                        to="/archiv/"
                    >
                        Weitere Artikel &raquo;
                    </Link>
                </div>
                <div className="text-center bg-blue-900">
                    <div className="container py-1">
                        <div className="flex flex-col lg:flex-row my-6 justify-between text-white whitespace-no-wrap">
                            <OutboundLink
                                className="group lg:1/5 text-left mr-0 lg:mr-6 mb-6 lg:mb-0 text-lg text-white hover:text-gray-500"
                                href="https://bicentenary.bahai.org/"
                                target="_blank"
                            >
                                Internationale 200 Jahrfeier Webseite
                                &nbsp;
                                <Icon
                                    icon="external-link"
                                    className="text-gray-600 text-sm" />
                            </OutboundLink>
                            <OutboundLink
                                className="group lg:1/5 text-left mr-0 lg:mr-6 mb-6 lg:mb-0 text-lg text-white hover:text-gray-500"
                                href="https://twitter.com/BahaiDE"
                                target="_blank"
                            >
                                <Icon icon="twitter" />
                                &nbsp;
                                Twitter
                                &nbsp;
                                <Icon
                                    icon="external-link"
                                    className="text-gray-600 text-sm" />
                            </OutboundLink>
                            <OutboundLink
                                className="group lg:1/5 text-left mr-0 lg:mr-6 mb-6 lg:mb-0 text-lg text-white hover:text-gray-500"
                                href="https://www.facebook.com/BahaiDeutschland/"
                                target="_blank"
                            >
                                <Icon icon="facebook" />
                                &nbsp;
                                Facebook
                                &nbsp;
                                <Icon
                                    icon="external-link"
                                    className="text-gray-600 text-sm" />
                            </OutboundLink>
                            <OutboundLink
                                className="group lg:1/5 text-left mr-0 lg:mr-6 mb-6 lg:mb-0 text-lg text-white hover:text-gray-500"
                                href="https://www.youtube.com/user/BahaiDE"
                                target="_blank"
                            >
                                <Icon icon="youtube" />
                                &nbsp;
                                YouTube
                                &nbsp;
                                <Icon
                                    icon="external-link"
                                    className="text-gray-600 text-sm" />
                            </OutboundLink>
                            <OutboundLink
                                className="group lg:1/5 text-left mr-0 lg:mr-6 mb-6 lg:mb-0 text-lg text-white hover:text-gray-500"
                                href="https://soundcloud.com/bahai_de/sets/einblicke-in-podcast"
                                target="_blank"
                            >
                                <Icon icon="podcast" />
                                &nbsp;
                                Podcast
                                &nbsp;
                                <Icon
                                    icon="external-link"
                                    className="text-gray-600 text-sm" />
                            </OutboundLink>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default BicentenaryHomepage;
