import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import {
    Container,
    TwoColumns,
    Sidebar,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

import Breadcrumbs from '@bahaide/shared/components/Breadcrumbs';
import Slideshow from '@bahaide/shared/components/Slideshow';

const ArticleTemplate = ({ data }) => {
    const { file } = data;
    const { childMarkdownRemark } = file;
    const { frontmatter, html, excerpt, fields } = childMarkdownRemark;

    return (
        <InnerPage
            pageType="single-post"
            title={frontmatter.title}
            description={excerpt}
            image={
                frontmatter.feature_image && frontmatter.feature_image.publicURL
            }
        >   
            <div className="intro-holder">
                <Container>
                    <Breadcrumbs
                        currentItem={frontmatter.title}
                    />
                    <h1>{frontmatter.title}</h1>
                    <div className="meta-info">
                        <time>{frontmatter.date}</time>
                        <h1 className="opacity-25 italic capitalize">{frontmatter.city}</h1>
                    </div>
                    {frontmatter.feature_image != null && frontmatter.gallery_images == null ? (
                        <div className="img-holder">
                            <Img
                                alt=""
                                width="480"
                                height="275"
                                fluid={
                                    frontmatter.feature_image.childImageSharp.fluid
                                }
                                className="attachment-full size-full"
                            />
                        </div>
                    ) : frontmatter.gallery_images != null ? (
                        <Slideshow
                            slides={frontmatter.gallery_images}
                        />
                    ) : null }
                </Container>
            </div>
            <TwoColumns>
                <div id="content" dangerouslySetInnerHTML={{ __html: html }} />
                <Sidebar>
                    <h3>TEILEN</h3>
                    <ul className="mb-4">
                        <li><OutboundLink target="_blank" href={`https://facebook.com/sharer/sharer.php?u=https://200jahrfeier.bahai.de/post${fields.slug}`}><i className="fa fa-facebook mr-4"></i>Facebook</OutboundLink></li>
                        <li><OutboundLink target="_blank" href={`https://twitter.com/intent/tweet/?text=${frontmatter.title} - ${excerpt}&url=https://200jahrfeier.bahai.de/post${fields.slug}`}><i className="fa fa-twitter mr-2"></i>Twitter</OutboundLink></li>
                    </ul>
                </Sidebar>
            </TwoColumns>
        </InnerPage>
    );
};

export default ArticleTemplate;

export const articlePageQuery = graphql`
    query articleQuery($slug: String!) {
        file(childMarkdownRemark: {fields: {slug: {eq: $slug}}}, sourceInstanceName: {eq: "posts-2019"}) {
            childMarkdownRemark {
                html
                excerpt(pruneLength: 100, format: PLAIN)
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "DD. MMMM YYYY", locale: "de")
                    title
                    city
                    feature_image {
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 80) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                    gallery_images {
                        image: image_file {
                            childImageSharp {
                                fluid(maxWidth: 1024, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
