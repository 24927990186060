import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import {
    Container,
    TwoColumns,
    Sidebar,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

import Breadcrumbs from '@bahaide/shared/components/Breadcrumbs';
import Slideshow from '@bahaide/shared/components/Slideshow';

const ArticleTemplate = ({ data }) => {
    const { file } = data;
    const { childMarkdownRemark } = file;
    const { frontmatter, html, excerpt } = childMarkdownRemark;

    return (
        <InnerPage
            pageType="single-post"
            title={frontmatter.title}
            description={excerpt}
        >
            <div className="intro-holder">
                <Container>
                    <Breadcrumbs
                        currentItem={frontmatter.title}
                    />
                    <h1>{frontmatter.title}</h1>
                    {frontmatter.feature_image != null && frontmatter.gallery_images == null ? (
                        <div className="img-holder">
                            <Img
                                alt=""
                                width="480"
                                height="275"
                                fluid={
                                    frontmatter.feature_image.childImageSharp.fluid
                                }
                                className="attachment-full size-full"
                            />
                        </div>
                    ) : frontmatter.gallery_images != null ? (
                        <Slideshow
                            slides={frontmatter.gallery_images}
                        />
                    ) : null }
                </Container>
            </div>

            <TwoColumns>
                <div id="content" dangerouslySetInnerHTML={{ __html: html }} />
                <Sidebar />
            </TwoColumns>
        </InnerPage>
    );
};

export default ArticleTemplate;

export const articlePageQuery = graphql`
    query post2017Query($slug: String!) {
        file(childMarkdownRemark: {fields: {slug: {eq: $slug}}}, sourceInstanceName: {eq: "posts-2017"}) {
            childMarkdownRemark {
                html
                excerpt(pruneLength: 100, format: PLAIN)
                frontmatter {
                    title
                    feature_image {
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 80) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                    gallery_images {
                        image: image_file {
                            childImageSharp {
                                fluid(maxWidth: 1024, quality: 80) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
