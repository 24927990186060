import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

const ArchivTemplate = ({ data, pageContext }) => {
    const posts = data["posts_2019"].edges;
    const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
        currentPage - 1 === 1
            ? '/archiv/'
            : '/archiv/seite/' + (currentPage - 1).toString();
    const nextPage = '/archiv/seite/' + (currentPage + 1).toString();
    return (
        <InnerPage
            pageType="blog-page"
            title="Liste aller Beiträge"
            description="Liste aller Beiträge"
        >
            <IntroHolder title="Liste aller Beiträge" />
            <TwoColumns>
                <Content>
                    {posts.map(({ node }, id) => {
                        return (
                            <article className="post-preview" key={id}>
                                <div className="text-holder">
                                    <h2 className="text-blue-900">
                                        <Link
                                            to={`/post${node.childMarkdownRemark.fields.slug}`}
                                        >
                                            {node.childMarkdownRemark.frontmatter.title}
                                        </Link>
                                    </h2>
                                    <div className="meta-info">
                                        {node.childMarkdownRemark.frontmatter.date}
                                    </div>
                                    <p>{node.childMarkdownRemark.excerpt}</p>
                                </div>
                            </article>
                        );
                    })}
                    <nav className="navigation pagination" role="navigation">
                        <div className="nav-links">
                            {!isFirst && (
                                <Link
                                    to={prevPage}
                                    className="prev page-numbers"
                                    rel="prev"
                                >
                                    Neuere Beiträge
                                </Link>
                            )}
                            {!isLast && (
                                <Link
                                    to={nextPage}
                                    className="next page-numbers"
                                    rel="next"
                                >
                                    Ältere Beiträge
                                </Link>
                            )}
                        </div>
                    </nav>
                </Content>
                <Sidebar />
            </TwoColumns>
        </InnerPage>
    );
};

export default ArchivTemplate;

export const archivQuery = graphql`
    query archivQuery($skip: Int!, $limit: Int!) {
        posts_2019: allFile(filter: {sourceInstanceName: {eq: "posts-2019"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, limit: $limit, skip: $skip) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            title
                            date(formatString: "DD. MMMM YYYY", locale: "de")
                            city
                            feature_image {
                                childImageSharp {
                                    fluid(maxWidth: 300, quality: 80) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                        excerpt(pruneLength: 70, format: PLAIN)
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    }
`;
